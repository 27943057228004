.@{steps-prefix-cls}-item-custom {
  .@{steps-prefix-cls}-item-icon {
    height: auto;
    background: none;
    border: 0;
    > .@{steps-prefix-cls}-icon {
      top: @steps-icon-custom-top;
      left: 4.5px;
      width: @steps-icon-custom-size;
      height: @steps-icon-custom-size;
      font-size: @steps-icon-custom-font-size;
      line-height: @steps-icon-custom-size;
    }
  }
  &.@{steps-prefix-cls}-item-process {
    .@{steps-prefix-cls}-item-icon > .@{steps-prefix-cls}-icon {
      color: @process-icon-color;
    }
  }
}

// Only adjust horizontal customize icon width
.@{steps-prefix-cls} {
  &:not(.@{steps-prefix-cls}-vertical) {
    .@{steps-prefix-cls}-item-custom {
      .@{steps-prefix-cls}-item-icon {
        width: auto;
        background: none;
      }
    }
  }
}
